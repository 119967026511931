import {useEmailRecommendation, usePayForSkinAnalysis} from "../hooks/api/useSkinAnalysisApi";
import {useForm} from "react-hook-form";
import {BoldText} from "./BoldText";
import {Button} from "./Button";
import {ListItem} from "./ListItem";
import {Input} from "./Input";
import {LoadingElement} from "./LoadingElement";

export const EmailForm = ({onSuccess, onFailed, analysis}) => {
    const analysisID = analysis?.tracking_key || ""
    const mutation = useEmailRecommendation()

    const {
        register,
        handleSubmit,
        formState: {errors, isValid},
    } = useForm({
        defaultValues: {
            email: '',
        },
        mode: 'onChange',
    })

    const onSubmit = ({email}) => {
        if (!analysisID) {
            return
        }

        mutation.mutate({email, id: analysisID}, {
            onSuccess: () => {
               onSuccess()
            },
        })
    }

    const isLoading = mutation.isPending

    return (
        <>

            <div className="flex flex-col items-center">

                <div className={'flex flex-col items-center p-8 bg-black bg-opacity-50 max-w-lg'}>

                    {isLoading &&
                    <LoadingElement
                        title={"Please wait"}
                        subTitle={"Please keep this window open while we process your request"}
                    />
                    }

                    {!isLoading &&
                    <>
                        <BoldText className={'text-white uppercase text-[24px] text-center'}>
                            Share your email
                        </BoldText>

                        <div className={'mt-8'}>
                            <ListItem label={'We will share personalized recommendations based on your results.'}/>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)} style={{marginTop: 40}}>
                            <Input
                                label="Email address"
                                placeholder={"Your email address"}
                                {...register('email', {
                                    required: 'Email is required',
                                })}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                            />

                            <Button
                                type="submit"
                                className={'w-full mt-8'}
                                disabled={!isValid || mutation.isPending}
                            >
                                Receive recommendations
                            </Button>
                        </form>
                    </>
                    }

                </div>
            </div>
        </>
    )
}
