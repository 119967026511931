import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import {Home} from "./pages/Home";
import {NotFound} from "./pages/NotFound";
import {GetStarted} from "./pages/GetStarted";
import {TakeSelfie} from "./pages/TakeSelfie";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import NotificationsProvider from "./hooks/utils/useNotifications";
import {Payment} from "./pages/Payment";
import {Results} from "./pages/Results";
import {EmailRecommendation} from "./pages/EmailRecommendation";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        errorElement: <NotFound/>
    },
    {
        path: "/get-started",
        element: <GetStarted/>,
    },
    {
        path: "/take-selfie",
        element: <TakeSelfie/>,
    },
    {
        path: "/payment/:id",
        element: <Payment/>,
    },
    {
        path: "/results/:id",
        element: <Results/>,
    },
     {
        path: "/email-recommendations/:id",
        element: <EmailRecommendation/>,
    },
]);

const queryClient = new QueryClient()

function App() {
    return (
        <div className={'h-full'}>
            <QueryClientProvider client={queryClient}>
                <NotificationsProvider>
                    <RouterProvider router={router}/>
                </NotificationsProvider>
            </QueryClientProvider>
        </div>
    );
}

export default App;
