import {Box} from "@mui/material";
import {Text} from "./Text";
import {BoldText} from "./BoldText";

export const ResultsHighlight = ({
                                     title,
                                     list = [],
                                     style = {},
                                     isPositive
                                 }) => {
    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                ...style,
            }}
        >

            <BoldText
                className={`${isPositive ? 'text-green-700' : 'text-red-700'} font-semibold mb-4 uppercase`}
            >
                {title}
            </BoldText>

            {list?.map((item, idx) => {
                return (
                    <div key={idx} className={'flex flex-col mb-8'}>
                        <Text
                            className={'font-semibold text-lg text-white'}
                        >
                            {item.title}
                        </Text>

                        <Text
                            className={'text-white mt-2'}
                        >
                            {item.description}
                        </Text>
                    </div>
                )
            })}
        </Box>
    )
}
