const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

let url = 'https://api.emkaystore.com'

if (IS_DEV) {
    url = 'http://localhost:9090'
}

export const Endpoints = {
    BASE_URL: url + "/api/v1/",
    NEW_SKIN_ANALYSIS: "skin-analysis/new",
    SKIN_ANALYSIS_PAYMENT: "skin-analysis/pay",
    SKIN_ANALYSIS_EMAIL: "skin-analysis/email-recommendation",
    FIND_SKIN_ANALYSIS: "skin-analysis/{{id}}",

    CHECK_PHONE: 'user/check-phone',
    VERIFY_PHONE: 'user/verify-phone',
    REGISTER_PHONE: 'user/register',

    NEW_ORDER: 'order/new',
    ORDER_LIST: 'order/list',
    PAY_FOR_ORDER: 'order/pay',
    SHIPPING_RATES: 'order/shipping-rates',
    UPDATE_SHIPPING_RATES: 'order/update-shipping-rates',

    FIND_ME: 'user/me',

    PRODUCT_LIST: 'product/list',
    PRODUCT_HOME: 'product/list-home',
    FIND_PRODUCT: 'product/{{id}}',

    CATEGORIES_LIST: 'category/list',

    BRANDS_LIST: 'brand/list',
}
