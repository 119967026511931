import {PageContainer} from "../components/PageContainer";
import {useNavigate, useParams} from "react-router-dom";
import {useFindSkinAnalysis} from "../hooks/api/useSkinAnalysisApi";
import {PaymentForm} from "../components/PaymentForm";
import {useState} from "react";
import {ConfirmationBox} from "../components/ConfirmationBox";
import {ANALYSIS_STATUS} from "../constants/status";
import {EmailForm} from "../components/EmailForm";

export const EmailRecommendation = () => {
    const navigate = useNavigate();

    const {id} = useParams()
    const analysisQuery = useFindSkinAnalysis(id)

    const [paymentComplete, setPaymentCompleted] = useState(false)

    const analysis = analysisQuery?.data?.data || {}
    const isReady = analysis?.status === ANALYSIS_STATUS.READY

    const onSuccess = () => {
        setPaymentCompleted(true)
    }

    const onFailed = () => {
        setPaymentCompleted(false)
    }


    return (
        <PageContainer
            showClose
            onClose={() => {
                navigate("/get-started")
            }}
        >
            {isReady && (
                <>
                    {paymentComplete ?
                        <div className="flex flex-col items-center mt-8">
                            <div className="flex flex-col items-center p-8 bg-black bg-opacity-50 max-w-lg">
                                <ConfirmationBox
                                    title={"Email received"}
                                    subTitle={`Your email has been received. You will receive personalized recommendations to this email`}
                                    btnLabel={"Back to results"}
                                    onBtnClick={() => {
                                        navigate(`/results/${id}`)
                                    }}
                                />
                            </div>
                        </div>
                        :
                        <EmailForm analysis={analysis} onFailed={onFailed} onSuccess={onSuccess}/>
                    }
                </>
            )}


        </PageContainer>
    )
}
